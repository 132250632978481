<template>
  <b-card-actions action-collapse title="Invoice Compare" no-body>
    <b-card-body>
      <b-row>
        <b-col cols="4" class="mb-2"
          ><b>New Invoice</b><div v-for="item in filterItems(newInvoiceData)" >
            <div style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: auto;
                  vertical-align: bottom;
                  max-width: 340px;
                  display: inline-block;
                ">{{ item.description }} </div> | qty  {{ item.quantity }} | ${{ item.rate }}</div
        ></b-col>
        <b-col cols="4" class="mb-2"
          ><b>Last Month</b><div v-for="item in filterItems(invoiceData[0].lineItems)" >
            <div style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: auto;
                  vertical-align: bottom;
                  max-width: 340px;
                  display: inline-block;
                ">{{ item.desc }} </div> | qty  {{ item.quantity }} | ${{ item.rate }}</div
        ></b-col>
        <b-col cols="4" class="mb-2"
          ><b>Two Months Ago</b><div v-for="item in filterItems(invoiceData[1].lineItems)" >
            <div style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: auto;
                  vertical-align: bottom;
                  max-width: 340px;
                  display: inline-block;
                ">{{ item.desc }} </div> | qty  {{ item.quantity }} | ${{ item.rate }}</div
        ></b-col>
      </b-row>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import {
  BTable, BOverlay, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    invoiceData: {
      type: Array,
      default: () => [],
    },
    newInvoiceData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      fields: [
        { key: 'itemCode', label: 'ITEM' },
        { key: 'description', label: 'DESCRIPTION' },
        { key: 'quantity', label: 'QUANTITY' },
        { key: 'rate', label: 'EACH' },
      ],
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeNewLine(str) {
      if (str) { return str.replace(/(?:\r\n|\r|\n)/g, '<br />') } 
      else { return str }
    },
    filterItems: function(items) {
      if (items[0].itemRef_FullName) { return items.filter(item => item.itemRef_FullName && !(item.itemRef_FullName === "Cortavo:Contract") && item.quantity > 0); }
      if (items[0].itemCode) { return items.filter(item => item.itemCode && !(item.itemCode === "Cortavo:Contract") && item.quantity > 0); }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
