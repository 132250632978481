<template>
<b-card-actions
    action-collapse
    title="New Invoice Check"
    no-body
  >
  <b-overlay
    :show="show"
    rounded="sm"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0 table-striped"
    >
      <template #cell(description)="data">
        <div class="d-flex align-items-center">
          <div style="width: 100%">
            <div
              style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-width: 750px;
                  width: auto;
                "
            >
              <span v-html="changeNewLine(data.item.description)" />
            </div>
          </div>
        </div>
      </template>
      <template #cell(quantity)="data">
        <div class="text-center">
          {{ data.item.quantity }}
        </div>
      </template>
      <template #cell(rate)="data">
        <div class="text-center">
          <span
            v-if="data.item.rate < 0"
            class="text-danger"
          >
            -{{ (data.item.rate * -1) | toCurrency() }}
          </span>
          <span v-if="data.item.rate > 0">
            {{ data.item.rate | toCurrency() }}
          </span>
        </div>
      </template>
    </b-table>
  </b-overlay>
  </b-card-actions>
</template>

<script>
import {
  BTable, BOverlay, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      fields: [
        { key: 'itemCode', label: 'ITEM' },
        { key: 'description', label: 'DESCRIPTION' },
        { key: 'quantity', label: 'QUANTITY' },
        { key: 'rate', label: 'EACH' },
      ],
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeNewLine(str) {
      if (str) { return str.replace(/(?:\r\n|\r|\n)/g, '<br />') } 
      else { return str }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
