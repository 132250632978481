<template>
  <b-card-actions action-collapse title="Unassigned Contacts" no-body>
    <b-card-body>
      <b-row v-for="contact in listItems" :key="contact.id" class="mb-1">
        <b-col
          cols="4"
          style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: auto;
                "
          >{{ contact.lastName }}, {{ contact.firstName }}<br />
          {{ contact.emailAddress }}
        </b-col>
        <b-col cols="8"
          ><div v-for="item in contact.billing">
            {{ item.billingProductName }}
          </div></b-col
        >
      </b-row>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import { BTable, BOverlay, BRow, BCol, BCardBody } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BCardActions,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    contactData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    listItems() {
      return this.contactData
        .filter((contact) => {
          return (
            contact.configItems.length === 0 &&
            !contact.billing.some((bill) =>
              bill.billingProductName.includes("No Charge")
            )
          );
        })
        .sort((a, b) => {
          // Sort based on the first billingProductName in each contact's billing array
          const aName =
            a.billing.length > 0 ? a.billing[0].billingProductName : "";
          const bName =
            b.billing.length > 0 ? b.billing[0].billingProductName : "";
          return aName.localeCompare(bName);
        });
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
