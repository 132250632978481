<template>
  <div>
    <!-- search input -->
    <b-progress v-if="searching" :value="barValue" max="100" />
    <section id="techops-topbox" v-if="!searching">
      <b-card
        :title="
          'Customer Information for ' + dataCustomer.company[0].companyName
        "
      >
        <b-overlay :show="searching" rounded="sm"> </b-overlay>
        <b-card-body>
      <b-row>
        <b-col cols="6">
        Company ID: {{ dataCustomer.company[0].id }}<br />
        QBID: {{ qbidValue }}<br />
        Configuration Items: {{ getItemCount(dataCustomer.configItems) }}<br />
        Contacts: {{ getItemCount(dataCustomer.contacts) }}
      </b-col>
      <b-col cols="6">
        Contract Information Filled Out?<br />
        Term: {{ termValue }}<br />
        Start Date: {{ startDateValue }}<br />
        Date Signed: {{  dateSignedValue }}<br />
        Minimum Users: {{ minimumUsersValue }}
        
      </b-col></b-row>
      </b-card-body>
      </b-card>
    </section>
    <section class="mt-n1" v-if="!searching && dataCustomer.configItems[0]">
      <invoiceCompare
        :invoice-data="dataCustomer.invoices"
        :new-invoice-data="dataCustomer.newInvoice"
      />
    </section>
    <section class="mt-n1" v-if="!searching && dataCustomer.configItems[0]">
      <configs :config-data="dataCustomer.configItems" />
    </section>
    <section class="mt-n1" v-if="!searching && dataCustomer.configItems[0]">
      <contactUn :contact-data="dataCustomer.contacts" />
    </section>
    <section class="mt-n1" v-if="!searching && dataCustomer.contacts[0]">
      <contact
        :contact-data="dataCustomer.contacts"
        :config-data="dataCustomer.configItems"
        :contract-billing-data="dataCustomer.contractbilling"
      />
    </section>
    <section v-if="!searching && dataCustomer.newInvoice[0]">
      <invoiceNew :table-data="dataCustomer.newInvoice" />
    </section>
    <section v-if="!searching && dataCustomer.invoices[0]">
      <invoice
        v-for="invoice in dataCustomer.invoices"
        :key="invoice.invoice[0].invoiceNumber"
        :table-data="invoice.lineItems"
        :invoice-data="invoice.invoice[0]"
      />
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BOverlay,
  BForm,
  BAvatar,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import Invoice from "./InvoiceCard.vue";
import InvoiceNew from "./InvoiceCard2.vue";
import InvoiceCompare from "./InvoiceCard3.vue";
import Contact from "./ContactCard.vue";
import Configs from "./ConfigCard.vue";
import ContactUn from "./ContactUnCard.vue";

export default {
  components: {
    BCard,
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BOverlay,
    BForm,
    BAvatar,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    Invoice,
    InvoiceNew,
    InvoiceCompare,
    Contact,
    Configs,
    ContactUn,
  },
  data() {
    return {
      timer: 0,
      timer2: 0,
      barValue: 0,
      searching: true,
      dataCustomer: [],
    };
  },
  computed: {
    qbidValue() {
      let qbid = null;
      this.dataCustomer.company[0].userDefinedFields.forEach((field) => {
        if (field.name === "QBID") {
          qbid = field.value;
        }
      });
      return qbid;
    },
    termValue() {
      let qbid = null;
      this.dataCustomer.contract[0].userDefinedFields.forEach((field) => {
        if (field.name === "Term") {
          qbid = field.value;
        }
      });
      return qbid;
    },
    startDateValue() {
      let qbid = null;
      this.dataCustomer.contract[0].userDefinedFields.forEach((field) => {
        if (field.name === "Start Date") {
          qbid = field.value;
        }
      });
      return qbid;
    },
    dateSignedValue() {
      let qbid = null;
      this.dataCustomer.contract[0].userDefinedFields.forEach((field) => {
        if (field.name === "Date Signed") {
          qbid = field.value;
        }
      });
      return qbid;
    },
    minimumUsersValue() {
      let qbid = null;
      this.dataCustomer.contract[0].userDefinedFields.forEach((field) => {
        if (field.name === "Minimum Users") {
          qbid = field.value;
        }
      });
      return qbid;
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0;
      }
      this.barValue += 5;
    }, 600);
  },
  async created() {
    this.customer = this.$route.params.id;
    this.searching = true;
    await this.getCustomerInformation();
    this.searching = false;
  },
  methods: {
    async getCustomerInformation() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/msCustomerInfo?id=${this.customer}&key=55d85562ab5f47dba870ae9caf584081`
        )
        .then((response) => {
          this.dataCustomer = response.data.results[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getItemCount(myObject) {
      return Object.keys(myObject).length;
    },
  },
};
</script>
<style>
.diagonal-box {
  background-color: #013a6b;
  background-image: -webkit-linear-gradient(30deg, #013a6b 50%, #004e95 50%);
}
.box-content-top {
  max-width: 50%;
  max-height: 50%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
}
.box-content-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50%;
  max-height: 50%;
  margin: auto;
  text-align: center;
}
</style>
