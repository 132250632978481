<template>
  <b-card-actions action-collapse title="Contact to Product Information" no-body>
    <b-card-body>
      <b-row
        ><b-col cols="6"
          ><b>Contact Associated Billing Items</b><div v-for="item in countBillingProductNames" :key="item.name">
            {{ item.name }}: {{ item.count }}
          </div></b-col
        ><b-col cols="6"
          ><b>Contract Billing Items</b><div v-for="item in contractBillingData" :key="item.id">
            {{ item.name }}
          </div></b-col
        ></b-row
      ><br><br>
      <b-row v-for="contact in listItems" :key="contact.id" class="mb-2">
        <b-col
          cols="3"
          style="
                  width: 100%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: auto;
                "
          >{{ contact.lastName }}, {{ contact.firstName }}<br />
          {{ contact.emailAddress }}
        </b-col>
        <b-col cols="6"
          ><div v-for="item in filterConfigItems(contact.id)">
            {{ item.referenceTitle }} ({{ item.rmmDeviceAuditLastUser }} -
            {{ item.serialNumber }} )<br />
            Installed: {{ item.installDate | moment("MMM Do, YYYY")
            }}<br /></div
        ></b-col>
        <b-col cols="3"
          ><div v-for="item in contact.billing">
            {{ item.billingProductName }}
          </div></b-col
        >
      </b-row>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import { BTable, BOverlay, BRow, BCol, BCardBody } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BCardActions,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    contactData: {
      type: Array,
      default: () => [],
    },
    configData: {
      type: Array,
      default: () => [],
    },
    contractBillingData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    listItems() {
      return this.contactData.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
    },
    countBillingProductNames() {
            const counts = this.contactData.reduce((count, contact) => {
                contact.billing.forEach(billing => {
                    const name = billing.billingProductName;
                    if (name in count) {
                        count[name]++;
                    } else {
                        count[name] = 1;
                    }
                });
                return count;
            }, {});

            // Convert counts object to an array
            const countsArray = Object.keys(counts).map(name => ({ name, count: counts[name] }));

            // Add total number of contacts
            countsArray.unshift({ name: '_All contacts', count: this.contactData.length });

            // Sort the array by name
            return countsArray.sort((a, b) => a.name.localeCompare(b.name));
        },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeNewLine(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    filterConfigItems(contactID) {
      return this.configData.filter((item) => item.contactID === contactID);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
