<template>
  <b-card-actions action-collapse title="Unassigned Configuration Items" no-body v-if="filterConfigItems()[0]">
    <b-card-body>
      <b-row>
        <b-col cols="6" v-for="item in filterConfigItems()" :key="item.id" class="mb-2"
          ><div >
            {{ item.referenceTitle }} ({{ item.rmmDeviceAuditLastUser }} -
            {{ item.serialNumber }} )<br />
            Installed: {{ item.installDate | moment("MMM Do, YYYY")
            }} and Active? {{ item.isActive }}<br />
            Operating System: {{ item.rmmDeviceAuditOperatingSystem
            }}<br /><div v-for="item2 in item.billing">
           Billing Items: {{ item2.billingProductName }}
          </div></div
        ></b-col>
      </b-row>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import { BTable, BOverlay, BRow, BCol, BCardBody } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  components: {
    BCardActions,
    BTable,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    configData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    listItems() {
      return this.contactData.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
    },
    countBillingProductNames() {
            const counts = this.contactData.reduce((count, contact) => {
                contact.billing.forEach(billing => {
                    const name = billing.billingProductName;
                    if (name in count) {
                        count[name]++;
                    } else {
                        count[name] = 1;
                    }
                });
                return count;
            }, {});

            // Convert counts object to an array
            const countsArray = Object.keys(counts).map(name => ({ name, count: counts[name] }));

            // Add total number of contacts
            countsArray.unshift({ name: 'All contacts', count: this.contactData.length });

            // Sort the array by name
            return countsArray.sort((a, b) => a.name.localeCompare(b.name));
        },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    changeNewLine(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    filterConfigItems() {
            return this.configData.filter((item) => {
                return item.contactID === null && !(!item.serialNumber || item.rmmDeviceAuditOperatingSystem.includes('Server'));
            });
        },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
<style>
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
